<template>
  <div>

    <div :disabled="item.disabled" v-for="(item,index) in globalArr" :key="index">
      <el-checkbox :disabled="item.disabled" v-model="item.checkAllBool" @change="handleCheckAllChange(item,index)">{{item.name}}
      </el-checkbox>
      <div style="margin: 15px 0;"></div>
      <el-checkbox-group v-model="item.checklist" @change="handleCheckedChange(item,index)">
        <el-checkbox :disabled="item.disabled" v-for="(itm,idx) in item.list" :label="itm.value" :key="idx">{{itm.name}}</el-checkbox>
      </el-checkbox-group>
    </div>

    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="globalSaveEvent">保 存</el-button>
    </span>

  </div>
</template>
<script>
export default {
  props: ['globalData'],
  data () {
    return {

      //   全球数据展示列表
      globalArr: [
        {
          isIndeterminate: true,
          checkAllBool: true,
          name: '全球数据库',
          promptText: '请至少选择一个数据库',
          "disabled":true,
          list: [{
            "name": "中国",
            "value": "CN",
            "disabled":true
          },
          {
            "name": "美国",
            "value": "US",
            "disabled":true
          },
          {
            "name": "欧洲",
            "value": "EU",
            "disabled":true
          },
          {
            "name": "英国",
            "value": "BR",
            "disabled":true
          },
          {
            "name": "日本",
            "value": "JA",
            "disabled":true
          },
          {
            "name": "韩国",
            "value": "SK",
            "disabled":true
          }
          ],
          checklist: ['CN'],
          type: 'country',
        },
        {
          isIndeterminate: true,
          checkAllBool: true,
          name: '专利类型',
          promptText: '请至少选择一个专利类型',
          "disabled":false,
          list: [
            {
              "name": "发明专利",
              "value": "A,B,C",
              "disabled":false
            },
            {
              "name": "实用新型专利",
              "value": "U,Y",
              "disabled":false
            },
            {
              "name": "外观设计专利",
              "value": "S",
              "disabled":false
            }
          ],
          checklist: ["A,B,C", "U,Y"],
          type: 'kinds'
        }
      ],
      globalDataArr: [],
    }
  },
  mounted () {
  },
  methods: {
    init () {
      this.globalDataArr['country'] = this.tool.getCookie("country") != undefined && this.tool.getCookie("country") != '' ? this.tool.getCookie("country").split(";") : ['CN']
      this.globalDataArr['kinds'] = this.tool.getCookie("kinds") != undefined && this.tool.getCookie("kinds") != '' ? this.tool.getCookie("kinds").split(";") : ["A,B,C", "U,Y"]
      // setTimeout(() => {
        Object.keys(this.globalDataArr).forEach(k => {
          this.globalArr.forEach((item, index) => {
            if (k == item.type) {
              this.globalDataArr[k].forEach(m => {
                item.checklist = this.globalDataArr[k]
                item.checkAllBool = item.checklist.length === item.list.length;
              })
            }
          })
        })
      // }, 300);
    },
    // 全选 功能
    handleCheckAllChange (val, index) {
      if (val.checkAllBool) {
        this.globalArr[index].list.forEach((itm, idx) => {
          this.globalArr[index].checklist.push(itm.value)
        })
      } else {
        this.globalArr[index].checklist = [];
      }
      this.globalArr[index].isIndeterminate = false;
    },
    // 多选 功能
    handleCheckedChange (val, index) {
      if (val.checklist.length == 0) {
        this.$message(`${val.promptText}`)
        return;
      }
      let checkedCount = val.checklist.length;
      this.globalArr[index].checkAllBool = checkedCount === this.globalArr[index].list.length;
    },
    // 选择全球数据集合
    globalSaveEvent () {
      var globalparamArr = [];
      this.globalArr.forEach((item, index) => {
        globalparamArr[item.type] = item.checklist;
      })
      if (globalparamArr['country'].length == 0) {
        this.$message("请至少选择一个数据库");
        return;
      }
      if (globalparamArr['kinds'].length == 0) {
        this.$message("请至少选择一个专利类型");
        return;
      }
      this.tool.setCookie("country", "")
      this.tool.setCookie("kinds", "")
      this.tool.setCookie("country", globalparamArr['country'].join(';'))
      this.tool.setCookie("kinds", globalparamArr['kinds'].join(';'))
      this.$emit("globalparamEvent", globalparamArr)
    },
  },
  watch: {     //监听value的变化，进行相应的操作即可
    "globalData": {
      immediate: true,
      handler: function (val) {
        if (val) {
          this.init();
        }
      }
    }
  }
}
</script>
<style lang="less">
.global_dialog {
  .el-button {
    display: block;
    width: 100px;
    height: 34px;
    line-height: 34px;
    padding: 0;
    border: 1px solid @blue;
    margin: 10px auto;
  }
  .el-dialog__header {
    .el-dialog__title {
      display: none;
    }
  }
  .el-dialog__body {
    .el-checkbox {
      .el-checkbox__label {
        color: #494d59;
        font-weight: 900;
      }
    }
    .el-checkbox-group {
      padding-left: 24px;
      .el-checkbox__label {
        color: #494d59;
        font-weight: inherit;
        margin-bottom: 20px;
      }
    }
  }
}
</style>
