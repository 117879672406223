<template>
  <div class="add_keywords_wrap">
    <div class="add_keywords_con" v-for="(item,index) in keywordsList" :key="index">
      <el-select class="add_keywords_select" v-model="item.condition" placeholder="请选择">
        <el-option v-for="(item,index) in conditionArr" :key="index" :label="item" :value="item">
        </el-option>
      </el-select>
      <el-select class="add_keywords_select" v-model="item.fieldName" placeholder="请选择"
        @change="literatureEvent(item,index)">
        <el-option-group v-for="group in literatureArr" :key="group.name" :label="group.name">
          <el-option v-for="item in group.list" :key="item.value" :label="item.name" :value="item.value">
          </el-option>
        </el-option-group>
      </el-select>
      <el-input class="add_keywords_input" v-show="item.type == 'input'" v-model="item.fieldValue" placeholder="请输入...">
      </el-input>
      <el-date-picker class="add_keywords_date" v-show="item.type == 'date'" v-model="item.dateStart"
        value-format="yyyy-MM-dd" type="date" placeholder="开始日期">
      </el-date-picker>
      <em style="margin:0 5px;line-height:34px;" v-show="item.type == 'date'">-</em>
      <el-date-picker class="add_keywords_date" v-show="item.type == 'date'" v-model="item.dateEnd"
        value-format="yyyy-MM-dd" type="date" placeholder="结束日期">
      </el-date-picker>
      <i @click.stop="(keywordsList.length > 1) ? delHurdlesEvent(index) : ''"></i>
    </div>
    <p class="addHurdlesBtn" @click="addHurdlesEvent">+ 新增栏位 </p>
  </div>
</template>
<script>
export default {
  data () {
    return {
      conditionArr: [
        'AND', 'OR', "NOT"
      ],
      literatureArr: [
        {
          name: '号码',
          type: 'input',
          list: [
            {
              'name': '申请号',
              'value': 'apply_no'
            },
            {
              'name': '公开号',
              'value': 'pub_no'
            }
          ]
        },
        {
          name: '日期',
          type: 'date',
          list: [
            {
              'name': '申请日',
              'value': 'apply_date'
            },
            {
              'name': '公开日',
              'value': 'pub_date'
            }
          ]
        }
      ],
      keywordsList: [
        {
          condition: 'AND',
          fieldName: 'apply_no',
          type: 'input',  // input  输入框   date  日期框
          fieldValue: '',
          dateStart: '',
          dateEnd: ''
        }
      ],
    }
  },
  methods: {
    literatureEvent (val, ide) {
      this.literatureArr.forEach((item, index) => {
        item.list.forEach((itm, idx) => {
          if (itm.value == val.fieldName) {
            this.keywordsList[ide].type = item.type
          }
        })
      })
    },
    // 新增栏位
    addHurdlesEvent () {
      var obj = {
        condition: 'AND',
        fieldName: 'apply_no',
        type: 'input',  // input  输入框   date  日期框
        fieldValue: '',
        dateStart: '',
        dateEnd: ''
      }
      this.keywordsList.push(obj);
      this.$emit('keyWordsListEvent',this.keywordsList)
    },
    // 删除栏位
    delHurdlesEvent (index) {
      this.keywordsList.splice(index, 1);
      this.$emit('keyWordsListEvent',this.keywordsList)
    },
  }
}
</script>
<style lang="less" scoped>
.add_keywords_wrap {
  width: 922px;
  padding: 30px 60px;
  border-radius: 5px;
  margin: 0 auto;
  border: solid 1px #c2c6d0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
  background: #fff;
  .add_keywords_con {
    display: flex;
    margin-bottom: 16px;
    i {
      display: block;
      width: 64px;
      background: #fff url("@{imgUrl}patent/del_icon.png") no-repeat left
        center;
      cursor: pointer;
      margin-left: 25px;
    }
    &:first-child {
      i {
        background: #fff;
        cursor: default;
      }
    }
  }
  .addHurdlesBtn {
    width: 190px;
    height: 34px;
    background: #eaecf1;
    border-radius: 5px;
    font-size: 14px;
    color: #515a71;
    line-height: 34px;
    text-align: center;
    cursor: pointer;
  }
}
</style>
<style lang="less">
.add_keywords_wrap {
  .add_keywords_select {
    margin-right: 20px;
    .el-input__inner {
      width: 190px;
      height: 34px;
    }
  }
  .add_keywords_input {
    height: 34px;
    .el-input__inner {
      height: 34px;
      line-height: 34px;
    }
  }
  .add_keywords_date {
    .el-input__inner {
      width: 156px;
      height: 34px;
    }
  }
}
</style>