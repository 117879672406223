<template>
  <div class="keyword__layer_wrap">
    <div :class="['keyword_input_box',{'hidden':keywordsList.length == 0}]">
      <input class="input_con" autofocus v-model="search" placeholder="请输入关键词"></input>
      <p class="query-btn" @click="queryEvent">查询</p>
    </div>
    <div class="keyword_list_wrap" v-show="keywordsList.length != 0">
      <ul class="keyword_list">
        <li v-for="(itm,idx) in keywordsList" :key="idx" @click="idx == 0 ? '' : chooseFn(itm,idx)"
          :class="{'active':itm.activeBool}">
          {{itm[0]}}</li>
      </ul>
      <span slot="footer" class="keyword_footer">
        <el-button type="primary" @click="keywordSureBtn">确 定</el-button>
        <el-button @click="cancelKeywordEvent">取 消</el-button>
      </span>
    </div>
  </div>
</template>
<script>
export default {
  props: ['keywordData'],
  data () {
    return {
      search: "",
      keywordsList: [],
    }
  },
  methods: {
    //   查询
    queryEvent () {
      if (this.search != '') {
        this.queryMethod();
      }
    },
    // 联想词接口  
    async queryMethod () {
      let data = await this.$axios.post('/patent/search/getVecSimilarWord', {
        "keyWord": this.search,
        "country": 'CN'
      }
      );
      if (data.data.code == 0) {
        data.data.data.vecSimilarWord.activeword_ret.forEach((item, index) => {
          this.$set(item, 'activeBool', false)
          if (index == 0) {
            this.$set(item, 'activeBool', true)
          }
        })
        this.keywordsList = data.data.data.vecSimilarWord.activeword_ret;
      }
    },
    /**点击选中*/
    chooseFn (item, index) {
      this.$set(item, 'activeBool', !item.activeBool)
      this.$set(this.keywordsList, index, item)
      if (item.activeBool) {
        this.search = this.search + " " + item[0];
      } else {
        var searchArr = this.search.split(" "),
          searchNewArr = [];
        searchArr.map((itm, idx) => {
          if (item[0] != itm) {
            searchNewArr.push(itm);
          }
        })
        this.search = searchNewArr.join(" ");
      }
    },
    // 确定事件
    keywordSureBtn () {
      var keywordsParam = [];
      this.keywordsList.forEach((item, index) => {
        if (item.activeBool) {
          keywordsParam.push(item[0])
        }
      })
      this.$emit("getKeywordsParam", keywordsParam)
    },
    // 取消事件
    cancelKeywordEvent () {
      this.$emit("getCancelKeyword", false)
    }
  },
  watch: {     //监听value的变化，进行相应的操作即可
    "keywordData": {
      immediate: true,
      handler: function (val) {
        if (val) {
          this.search = "";
          this.keywordsList = [];
        }
      }
    }
  }
}
</script>
<style lang="less" scoped>
.keyword__layer_wrap {
  width: 517px;
  margin: 0 auto;
  .keyword_input_box {
    display: flex;
    justify-content: space-between;
    &.hidden {
      margin: 100px auto 120px;
    }
    .input_con {
      width: 420px;
      height: 34px;
      padding-left: 10px;
      border-radius: 3px;
      border: solid 1px #c2c6d0;
      outline: none; // 去除选中状态边框
      background-color: rgba(0, 0, 0, 0); // 透明背景
      box-sizing: border-box;
    }
    .query-btn {
      width: 80px;
      height: 34px;
      line-height: 34px;
      background-color: @blue;
      border-radius: 3px;
      text-align: center;
      letter-spacing: 1px;
      color: #ffffff;
      cursor: pointer;
      &:hover {
        box-shadow: @boxShadow;
      }
    }
  }
  .keyword_list {
    min-height: 168px;
    border-radius: 3px;
    border: solid 1px #c2c6d0;
    margin: 43px auto 18px;
    padding: 10px 14px;
    box-sizing: border-box;
    li {
      display: inline-block;
      margin: 6px;
      padding: 0 16px;
      line-height: 30px;
      background-color: #edeff2;
      border-radius: 3px;
      font-size: 14px;
      color: #6c7383;
      letter-spacing: 1px;
      cursor: pointer;
      &:nth-child(1) {
        cursor: default;
        background-color: #cfe4ff;
        color: @blue;
      }
      &.active {
        background-color: #cfe4ff;
        color: @blue;
      }
    }
  }
  .keyword_footer {
    display: flex;
    justify-content: flex-end;
  }
}
</style>
<style lang="less">
.keyword_footer {
  .el-button {
    padding: 0;
    width: 60px;
    height: 34px;
    line-height: 34px;
  }
}
</style>